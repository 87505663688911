import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';
import { MobileEventService } from '../mobile-event.service'



@Component({
  selector: 'app-event-report',
  templateUrl: './event-report.component.html',
  styleUrls: ['./event-report.component.css']
})
export class EventReportComponent implements OnInit {
  private event: any

  constructor(
    private route: ActivatedRoute, 
    private rt: Router, 
    private mobileEvent: MobileEventService
    ) { }


  ngOnInit() {
    this.mobileEvent.getEventReport(this.route.snapshot.paramMap.get('id'))
    .subscribe(data => console.log(data))
  }

}
