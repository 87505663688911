import { Component, OnInit } from '@angular/core';
import { FiredbService, Resource} from '../firedb.service';
import { ActivatedRoute, Router  } from '@angular/router';

@Component({
  selector: 'app-resource-create',
  templateUrl: './resource-create.component.html',
  styleUrls: ['./resource-create.component.css']
})
export class ResourceCreateComponent implements OnInit {

  private sub: any;
  eventid: string;
  resdata: Resource = <Resource> {};
  eethimage: string;

  constructor(private fs: FiredbService, private route: ActivatedRoute,private rt: Router) { 

  
  }

  ngOnInit() {
  }

  createResource() {
    console.log('Resource added');
    this.fs.pushResource(this.resdata);
    this.rt.navigate(['resources']);
    
    

  }


}
