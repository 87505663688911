import { Component, OnInit } from '@angular/core';

import {  AngularFirestoreCollection} from '@angular/fire/firestore';;

import {FiredbService, Message} from '../firedb.service';
import { Observable } from 'rxjs/Observable';
@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.css']
})
export class MessageListComponent implements OnInit {

  esgomsgCollectionRef: AngularFirestoreCollection<Message>;
  esgomsg$: Observable<Message[]>;
  esgomsgLength: number;
  constructor(private fs: FiredbService) { }

  ngOnInit() {
   this.getMessages();
    
  }

  getMessages(){
    this.esgomsgCollectionRef = this.fs.getMessages();
    this.esgomsg$ = this.esgomsgCollectionRef.snapshotChanges().map(actions => {
      this.esgomsgLength = 0;
      return actions.map(action => {
        this.esgomsgLength++;
        const data = action.payload.doc.data() as Message;
        const id = action.payload.doc.id;
        return { id, ...data };
      });
    });

  }
  removeMessage(msgid,title) {
    let del: boolean;

    del = confirm("Remove Message:" + title);
    if(del) {
      //alert("BOOM! events/" + eventid);
      this.fs.removeMessage(msgid);
      this.getMessages();
    }
  }
  

}

