import { Component, OnInit } from '@angular/core';
import {FiredbService, EsgoEvent} from '../firedb.service';
import { ActivatedRoute, Router  } from '@angular/router';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.css']
})
export class EventEditComponent implements OnInit {
 evnt: Observable<EsgoEvent>;
 private sub: any;
 eventid: string;
 private evntdata: any;
  constructor( private fs: FiredbService, private route: ActivatedRoute,private rt: Router) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.eventid = params['id']; 
      this.getEvent(this.eventid);
      

   });

  }
  getEvent(eventid) {
   this.evnt =  this.fs.getEvent(eventid).snapshotChanges().map(fsdata => {
     
      const data = fsdata.payload.data() as EsgoEvent;
      return data;
    });
    this.evnt.subscribe(dt => this.evntdata = dt);
  }

  pushEvent() {
    this.fs.updateEvent(this.eventid, this.evntdata);
    this.rt.navigate(['events']);
  }

}
