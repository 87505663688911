import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from '@angular/fire/auth';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private user: UserService, private route: Router, private toastr: ToastrService,private firebaseAuth : AngularFireAuth) { }

  ngOnInit() {
  }

  doLogin(e) {
    e.preventDefault();
    console.log(e);
    //let email = "admin@admin.com";
    //let password1= "esgo12345"
   // console.log('logging in:' + e.target.elements[0].value);
   var email = e.target.elements[0].value;
   var password = e.target.elements[1].value;
   
    this.firebaseAuth
    .auth
    .signInWithEmailAndPassword(email, password)
    .then(value => {
      this.toastr.success("access granted");
      //var allow = this.user.checkLogin(email,password);
      this.user.UserLoggedIn = true;
      this.route.navigate(['/events']);
     // allow ? this.route.navigate(['events']): this.toastr.error("Failure","Invalid Login/Password");
    })
    .catch(err => {
      console.log('Something went wrong:',err.message);
      this.toastr.error("Failure","Invalid Login/Password");
    });
  //  if (allow){
  //     this.toastr.success("access granted");
  //     this.route.navigate(['events']);
  //  }
  //  else {
  //    this.toastr.error("Failure","Invalid Login/Password");
  //  }
  }

  doAlert( msg:string ) {
    //this.toastr.success(msg);
  }

}
