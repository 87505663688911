import { Component, OnInit } from '@angular/core';
import { FiredbService, EsgoEvent} from '../firedb.service';
import { ActivatedRoute, Router  } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-event-create',
  templateUrl: './event-create.component.html',
  styleUrls: ['./event-create.component.css']
})
export class EventCreateComponent implements OnInit {
  
  private sub: any;
  eventid: string;
  evntdata: EsgoEvent = <EsgoEvent> {};
  eethimage: string;
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;

  constructor(private fs: FiredbService, private route: ActivatedRoute, private rt: Router) { 
  
  }

  ngOnInit() {
  }

  createEvent() {
    console.log('evnt added');
    this.fs.pushEvent(this.evntdata);
    this.rt.navigate(['events']);
    
  }
  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
        // Size Filter Bytes
        const max_size = 20971520;
        const allowed_types = ['image/png', 'image/jpeg'];
        const max_height = 15200;
        const max_width = 25600;

        if (fileInput.target.files[0].size > max_size) {
            this.imageError =
                'Maximum size allowed is ' + max_size / 1000 + 'Mb';

            return false;
        }

        if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
            this.imageError = 'Only Images are allowed ( JPG | PNG )';
            return false;
        }
        const reader = new FileReader();
        reader.onload = (e: any) => {
            const image = new Image();
            image.src = e.target.result;
            image.onload = rs => {
                const img_height = rs.currentTarget['height'];
                const img_width = rs.currentTarget['width'];

                if (img_height > max_height && img_width > max_width) {
                    this.imageError =
                        'Maximum dimentions allowed ' +
                        max_height +
                        '*' +
                        max_width +
                        'px';
                    return false;
                } else {
                    const imgBase64Path = e.target.result;
                    this.cardImageBase64 = imgBase64Path;
                    this.evntdata.image = imgBase64Path;
                    this.isImageSaved = true;
                    // this.previewImagePath = imgBase64Path;
                }
            };
        };

        reader.readAsDataURL(fileInput.target.files[0]);
    }
    
} 
removeImage() {
  this.cardImageBase64 = null;
  this.isImageSaved = false;
}

}
