import { Component, OnInit } from '@angular/core';
import { MobileEventService } from '../../mobile-event.service';

@Component({
  selector: 'app-data-adverts',
  templateUrl: './data-adverts.component.html',
  styleUrls: ['./data-adverts.component.css']
})
export class DataAdvertsComponent implements OnInit {

  rData: any;
  constructor(public db: MobileEventService) {
    this.db.getAdvertData().subscribe(dt =>{
      this.rData = dt
    })
   }

  ngOnInit() {

  }
}
