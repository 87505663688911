import { Component, OnInit } from '@angular/core';
import { MobileEventService } from '../../mobile-event.service';

@Component({
  selector: 'app-data-push',
  templateUrl: './data-push.component.html',
  styleUrls: ['./data-push.component.css']
})
export class DataPushComponent implements OnInit {

  rData: any;
  constructor(public db: MobileEventService) {
    this.db.getPushData().subscribe(dt =>{
      this.rData = dt
    })
   }

  ngOnInit() {

  }
}
