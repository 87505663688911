import { Component, OnInit } from '@angular/core';
import {  AngularFirestoreCollection} from '@angular/fire/firestore';

import {FiredbService, EsgoEvent} from '../firedb.service';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit {

  esgoeventCollectionRef: AngularFirestoreCollection<EsgoEvent>;
  esgoevent$: Observable<EsgoEvent[]>;
  esgoeventLength: number;
  constructor(private fs: FiredbService) { }

  ngOnInit() {
   this.getEvents();
    
  }

  getEvents(){
    this.esgoeventCollectionRef = this.fs.getEvents();
    this.esgoevent$ = this.esgoeventCollectionRef.snapshotChanges().map(actions => {
      this.esgoeventLength = 0;
      return actions.map(action => {
        this.esgoeventLength++;
        const data = action.payload.doc.data() as EsgoEvent;
        const id = action.payload.doc.id;
        return { id, ...data };
      });
    });

  }
  removeEvent(eventid,title) {
    let del: boolean;

    del = confirm("Remove Event ID:" + title);
    if(del) {
      //alert("BOOM! events/" + eventid);
      this.fs.removeEvent(eventid);
      this.getEvents();
    }
  }
  popEvents() {

    let events = [
      {
        title:'ESGO 2019 Congress',
        address:'Athens, Greece',
        date:'Date: Nov 2-5, 2019',
        image:'athens-2019-final-vs2.png',
        description: '<p> ESGO’s biennial International Meeting is Europe’s landmark congress in gynaecologicaloncology, bringing together 3000+ worldwide attendees and offering professionals in the field a unique opportunity to learn and discuss the latest medical and scientific developments in gynaecologicalcancers treatment and care, as well as to network with world experts and international peers </p> <p><b>Save the date for the ESGO 2019 Congress in Athens, Greece</b> </p> <p>Find out more onEurope’s leading Gynae-Oncology Congress</p>',
        website: 'https://www.esgo.org/attend/congress/'
      },
      {
        title:'State of the Art Conference',
        address:'Lyon, France',
        date:'Date: Oct 4-6, 2018',
        image:'lyon-2018-vs3.png',
        description: '<p>ESGO’s State of the Art Conference series, a single topic gathering taking place in between the years of  ESGO’s traditional biennial International meeting, focus on a specific emerging topic in gynaecological cancer treatment and care, presenting unique  opportunities to interact with leading experts and    discuss the latest findings in these developing    areas..</p><p>The 3rd ESGO State of the Art Conference in Lyon, France, 2018, will focus on<br><b>  New insights in Rare Gynaecological   Malignancies.</b></p><p>    Find out more!</p>  ',
        website: 'https://soaconference.esgo.org'
      },
      
    ];

    this.fs.pushEvent(events[0]);
    this.fs.pushEvent(events[1]);
    alert('Uploaded');
    
  }

}
