import { Component, OnInit } from '@angular/core';
import { FiredbService, Resource} from '../firedb.service';
import { ActivatedRoute, Router  } from '@angular/router';
import { Observable } from 'rxjs/Observable';
@Component({
  selector: 'app-resource-edit',
  templateUrl: './resource-edit.component.html',
  styleUrls: ['./resource-edit.component.css']
})
export class ResourceEditComponent implements OnInit {
  res: Observable<Resource>;
  private sub: any;
  resid: string;
  resdata: Resource = <Resource> {};
  eethimage: string;

  constructor(private fs: FiredbService, private route: ActivatedRoute,private rt: Router) { 

  
  }
  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.resid = params['id']; 
      this.getResource(this.resid);
      

   });
  }
  cancel() {
    this.rt.navigate(['resources']);

  }

  getResource(resid:string) {
    this.res =  this.fs.getResource(resid).snapshotChanges().map(fsdata => {
      
      const data = fsdata.payload.data() as Resource;
      return data;
    });
    this.res.subscribe(dt => this.resdata = dt);

  }
  
 
   pushResource() {
     this.fs.updateResource(this.resid, this.resdata);
     this.rt.navigate(['resources']);
   }
}
