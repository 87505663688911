import { Component, OnInit } from '@angular/core';
import { FiredbService } from '../firedb.service';
import {  AngularFirestoreCollection} from '@angular/fire/firestore';
import { EsgoAdvertisement } from './../firedb.service';
import { Observable } from 'rxjs/Observable';
import { isDate } from 'util';

@Component({
  selector: 'app-advertisements',
  templateUrl: './advertisements.component.html',
  styleUrls: ['./advertisements.component.css']
})
export class AdvertisementsComponent implements OnInit {

  esgoeventCollectionRef: AngularFirestoreCollection<EsgoAdvertisement>;
  esgoAds:any;
  
  constructor(
    public fs : FiredbService
  ) { }

  ngOnInit() {
    this.getAds();
  }

  getAds(){
    this.esgoeventCollectionRef = this.fs.getAdvertisements();
    this.esgoeventCollectionRef.snapshotChanges().map(actions => {
     
      return actions.map(action => {
       
        const data = action.payload.doc.data() as EsgoAdvertisement;
        const id = action.payload.doc.id;
        return { id, ...data };
      });
    }).subscribe( ads =>{
      this.esgoAds= ads
    });

  }
  removeAd(id,title) {
    let del: boolean;

    del = confirm("Remove Event ID:" + title);
    if(del) {
      //alert("BOOM! events/" + eventid);
      this.fs.removeAdvertisement(id);
     // this.getEvents();
    }
  }
  
}
