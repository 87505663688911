import { Component, OnInit } from '@angular/core';
import { FiredbService, Message} from '../firedb.service';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute, Router  } from '@angular/router';


@Component({
  selector: 'app-message-edit',
  templateUrl: './message-edit.component.html',
  styleUrls: ['./message-edit.component.css']
})
export class MessageEditComponent implements OnInit {
  msg: Observable<Message>;
  msgid: String;
  private sub: any;
  msgdata: Message = <Message>{};
  constructor(private fs:FiredbService, private route: ActivatedRoute, private rt: Router) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.msgid = params['id']; 
      this.getMessage(this.msgid);
      

   });

  }
  getMessage(msgid) {
   this.msg =  this.fs.getMessage(msgid).snapshotChanges().map(fsdata => {
     
      const data = fsdata.payload.data() as Message;
      return data;
    });
    this.msg.subscribe(dt => this.msgdata = dt);
  }
  pushMessage(){

    this.fs.updateMessage(this.msgid, this.msgdata);
    this.rt.navigate(['messages']);

  }
  cancel(){
    this.rt.navigate(['messages'])
  }
}
