import { Component, OnInit } from '@angular/core';
import { MobileEventService } from '../../mobile-event.service';

@Component({
  selector: 'app-all-data',
  templateUrl: './all-data.component.html',
  styleUrls: ['./all-data.component.css']
})
export class AllDataComponent implements OnInit {

  rData: any;
  constructor(public db: MobileEventService) {
    this.db.getRawData().subscribe(dt =>{
      this.rData = dt
    })
   }

  ngOnInit() {

  }

}
