import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';

@Component({
  selector: 'app-menu-admin',
  templateUrl: './menu-admin.component.html',
  styleUrls: ['./menu-admin.component.css']
})
export class MenuAdminComponent implements OnInit {
  bShow:boolean;
  constructor(private user: UserService) {
    this.bShow = this.user.isUserLoggedIn();
   }

  ngOnInit() {
  }

}
