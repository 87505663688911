import { Component, OnInit } from '@angular/core';
import {FiredbService, Message} from '../firedb.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-message-create',
  templateUrl: './message-create.component.html',
  styleUrls: ['./message-create.component.css']
})
export class MessageCreateComponent implements OnInit {
  msgdata: Message = <Message>{};
  constructor(private fs:FiredbService, private rt: Router) { }

  ngOnInit() {
  }

  pushMessage(){

    this.fs.pushMessage(this.msgdata)
    this.rt.navigate(['messages']);

  }
}
