import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FiredbService, PocketGuide } from '../firedb.service';

@Component({
  selector: 'app-pocketguide-create',
  templateUrl: './pocketguide-create.component.html',
  styleUrls: ['./pocketguide-create.component.css']
})
export class PocketguideCreateComponent implements OnInit {
  gd: PocketGuide = <PocketGuide>{};
  constructor(private fs: FiredbService, private rt: Router) {

   }

  ngOnInit() {
  }
  pushGuide(){
    console.log(this.gd);
    this.fs.pushPocketGuide(this.gd)
    this.rt.navigate(['pocketguides']);

  }
}
