import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

let config  = {
  baseUrl: 'https://app.esgo.org:4000'
}

@Injectable()
export class MobileEventService {
  constructor(private http: HttpClient) { }

  getEventReport(msgId: String) {
    return this.http.get(config.baseUrl + `/mobile-event/${msgId}`)
  }
  getRawData(){
    return this.http.get(`${config.baseUrl}/mobile-event/`)
  }
  getMessageData(){
    return this.http.get(`${config.baseUrl}/mobile-event-messages/`)
  }
  getAdvertData(){
    return this.http.get(`${config.baseUrl}/mobile-event-adverts/`)
  }
  getPushData(){
    return this.http.get(`${config.baseUrl}/mobile-event-push/`)
  }
  

}
