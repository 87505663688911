import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import 'rxjs/add/operator/map';
import { firestore } from 'firebase';
import { findStaticQueryIds } from '@angular/compiler';

@Injectable()
export class FiredbService {

  constructor(public firedb: AngularFirestore) {
    

  }

  // ------  Events  -------- //
  public  getEvents() {
    return this.firedb.collection<EsgoEvent>('events', ref => ref.orderBy('order'));
  }
  public getEvent(eventid) {
    return this.firedb.collection('events').doc<EsgoEvent>(eventid);
  }
  public removeEvent(eventid) {
    //this.firedb.collection('events').doc(eventid).delete();
    this.firedb.doc('events/' + eventid).delete();
  }

  public  pushEvent(evnt: EsgoEvent) {
    this.firedb.collection('events').add(evnt);
  }
 // -------  Adverts -------------- //

  public pushAdvertisement(advt:EsgoAdvertisement){
    
    this.firedb.collection('adverts').add(advt);
  }

  public getAdvertisements() {
   
    return this.firedb.collection<EsgoAdvertisement>('adverts');
  } 
  public getAdvertisement(id: string){
    return this.firedb.doc<EsgoAdvertisement>(`/adverts/${id}`).valueChanges()
  }
  public updateAdvertisement(id:string, advert: EsgoAdvertisement){
    //Convert Dates to ISO STRING
    this.firedb.doc(`/adverts/${id}`).update(advert)
  }
  public removeAdvertisement(id:string) {
    //this.firedb.collection('events').doc(eventid).delete();
    this.firedb.doc('adverts/' + id).delete();
  }
  public updateEvent(eventid, evnt: EsgoEvent) {
    this.firedb.doc('events/' + eventid).update(evnt);
  }

  // ------  App Users / Members  -------- //

  public getMembers() {
    return this.firedb.collection<AppUser>('appusers');
  }



  // ------  App Messages  -------- //
  public getMessages() {
    // this.afd.ref("https://esgo-guides-dev.firebaseio.com/messages");
    return this.firedb.collection<Message>('messages', ref => ref.orderBy('date'));
  }

  public getMessage(msgid) {
    return this.firedb.collection('messages').doc<Message>(msgid);

  }
  public pushMessage(msg: Message) {
    this.firedb.collection('messages').add(msg);
  }
  public updateMessage(msgid, msg: Message) {
    this.firedb.doc('messages/' + msgid).update(msg);
  }
  public removeMessage(msgid) {
    //this.firedb.collection('events').doc(eventid).delete();
    this.firedb.doc('messages/' + msgid).delete();
  }

  // ------  Resources  -------- //
  public getResources(category: string) {
    // this.afd.ref("https://esgo-guides-dev.firebaseio.com/messages");
    if(category=='all'){
      return this.firedb.collection<Resource>('resources', ref => ref.orderBy('order'));
    }
    else {
      return this.firedb.collection<Resource>('resources', ref => ref.where('category','==',category));
    }
    
  }

  public getResource(resid) {
    return this.firedb.collection('resources').doc<Resource>(resid);

  }


  public pushResource(res: Resource) {
    this.firedb.collection('resources').add(res);
  }
  public updateResource(resid, res: Resource) {
    this.firedb.doc('resources/' + resid).update(res);
  }
  public removeResource(resid) {
    //this.firedb.collection('events').doc(eventid).delete();
    this.firedb.doc('resources/' + resid).delete();
  }

  //Push Notes
  public getPushNotes() {
    
    return this.firedb.collection<PushNote>('pushnotes', ref => ref.orderBy('senddate'));
    
  }
  public pushPushNote(ps:PushNote){
    this.firedb.collection('pushnotes').add(ps);

  }
  public removePushNotes(pnid: string) {
    this.firedb.doc('pushnotes/'+ pnid).delete();
  }

  //PocketGuides
  public getPocketGuides(){

    return this.firedb.collection<PocketGuide>('pocketguides', ref => ref.orderBy('order'));
    
  }
  public getPocketGuide(gdid:string){

    return this.firedb.collection('pocketguides').doc<PocketGuide>(gdid);
  }
  
  public pushPocketGuide(gd: PocketGuide) {
    this.firedb.collection('pocketguides').add(gd);
  }
  public removePocketGuide(gdid: string){
    this.firedb.doc('pocketguides/'+ gdid).delete();
  }
  public updatePocketGuide(gd: PocketGuide,gdid: string){
    this.firedb.doc('pocketguides/'+ gdid).update(gd);
  }

  // ---------- COMMENTS ----------------- //

  public getComments(){

    return this.firedb.collection('guidecomments', ref => ref.orderBy('createdAt'));
    
  }
  public getComment(gdid:string){

    return this.firedb.collection('guidecomments').doc(gdid);
  }
  
  public removeComment(gdid: string){
    this.firedb.doc('guidecomments/'+ gdid).delete();
  }
  public updateComment(gd: PocketGuide,gdid: string){
    this.firedb.doc('guidecomments/'+ gdid).update(gd);
  }

  public convertTS( cdate: string){
    let cDate = new Date(cdate)
    
    return  firestore.Timestamp.fromDate(cDate)
  }
  public fsDate(myDate: any){
    let x = myDate.split('T')
    if (x.length > 1){
      return new Date(myDate).toUTCString()
    }
  }
  
}

export interface Comment {
  date?: Date;
  author: string;
  text: string;
  status: string;
  guide: string;
}
export interface Message {
  title?: string;
  date?: Date;
  description: string;
  uri?: string;
  expires?: Date;

}
export interface AppUser {
  id?: string,
  firstname: string,
  lastname: string,
  useremail: string,
  userpassword: string,
  company?: string,
  country?: string,
  professionalrole: string,
  specialty: string,
  avatar?: string,
  ismember?: string
}
export interface PocketGuide {
  title:string,
  thumb:string,
  image:string;
  description: string,
  order?:number,
  languages?:any[],
}
export interface EsgoEvent {
  order?: number,
  title: string,
  address: string,
  date: string,
  image?: string,
  description: string,
  website: string
}

export interface EsgoAdvertisement {
  id?: string,
  advertSrc?: string,
  category: string,
  desciption: string,
  endDate?: string,
  guide: string,
  startDate: string,
  title: string,
  url:string,
  urlName:string 
}

export interface Resource {
  order?: number,
  category: string,
  guide: string,
  title: string,
  description?: string,
  url:string,
  url_title?:string,
  footer?:string

}
export interface PushNote {
  senddate: string,
  txtmsg: string,
}