import { Component, OnInit } from '@angular/core';
import {  AngularFirestoreCollection} from '@angular/fire/firestore';

import {FiredbService, AppUser} from '../firedb.service';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-members-list',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.css']
})
export class MembersListComponent implements OnInit {
  appuserCollectionRef: AngularFirestoreCollection<AppUser>;
  appuser$: Observable<AppUser[]>;
  appuserLength: number;
  constructor(public fs: FiredbService) { }

  ngOnInit() {
    this.appuserLength=0;
    this.appuserCollectionRef = this.fs.getMembers();
    this.appuser$ = this.appuserCollectionRef.snapshotChanges().map(actions => {
      return actions.map(action => {
        this.appuserLength++;
        const data = action.payload.doc.data() as AppUser;
        const id = action.payload.doc.id;
        return { id, ...data };
      });
    });
  }

}
