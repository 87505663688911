import { Injectable } from '@angular/core';

@Injectable()
export class UserService {
 public UserLoggedIn: boolean = false;
 
  constructor() { }

  checkLogin(username: string, password: string) {
    if (username =='admin@admin.com' && password=="esgo12345") {
      this.UserLoggedIn = true;
      return true;
    }
    else {
      this.UserLoggedIn = false;
      return false;
    }
  }

  isUserLoggedIn() {
    return this.UserLoggedIn;
  }

}
