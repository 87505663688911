import { Component, OnInit } from '@angular/core';
import {  AngularFirestoreCollection} from '@angular/fire/firestore';;

import {FiredbService, PocketGuide} from '../firedb.service';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-pocketguide-list',
  templateUrl: './pocketguide-list.component.html',
  styleUrls: ['./pocketguide-list.component.css']
})
export class PocketguideListComponent implements OnInit {
  pgCollectionRef: AngularFirestoreCollection<PocketGuide>;
  pg$: Observable<PocketGuide[]>;
  pgLength: number;

  guides = [
    {
      title:"ENDOMETRIAL CANCER",
      thumb:"endometrial_cancer_thumb.png",
      image:"endometrial_cancer_image.png",
  
      description: "The European Society of Gynaecological Oncology (ESGO)<br><br>, the European Society for Radiotherapy and Oncology (ESTRO), and the European Society of Pathology    (ESP) jointly developed clinically relevant and evidence-based guidelines covering    comprehensively cervical cancer staging, management and follow-up for patients    with cervical. ",
     
      languages: [
         {"name":"English","url":"http://pdf.esgo.org"},
         {"name":"Francais","url":"http://pdf.esgo.org"},
         {"name":"Roma","url":"http://pdf.esgo.org"}
      ],
      order:1,
      
    },
    {
      title:"OVARIAN CANCER",
      thumb:"ovarian_cancer_thumb.png",
      image:"ovarian_cancer_image.png",
  
      description: "The European Society of Gynaecological Oncology (ESGO), the European Society for Radiotherapy and Oncology (ESTRO), and the European Society of Pathology    (ESP) jointly developed clinically relevant and evidence-based guidelines covering    comprehensively cervical cancer staging, management and follow-up for patients    with cervical. ",
     
      languages: [
        {"name":"English","url":"http://pdf.esgo.org"}
     ],
      order:2,
      
    },
    {
      title:"VULVAR CANCER",
      thumb:"vulvar_cancer_thumb.png",
      image:"vulvar_cancer_image.png",
  
      description: "The European Society of Gynaecological Oncology (ESGO), the European Society for Radiotherapy and Oncology (ESTRO), and the European Society of Pathology    (ESP) jointly developed clinically relevant and evidence-based guidelines covering cervical cancer staging, management and follow-up for patients    with cervical. ",
     
      languages: [
        {"name":"English","url":"http://pdf.esgo.org"}
     ],
      order:3,
      
    },
    {
      title:"CERVICAL CANCER",
      thumb:"cervical_cancer_thumb.png",
      image:"cervical_cancer_image.png",
      description: "The European Society of Gynaecological Oncology (ESGO), the European Society for Radiotherapy and Oncology (ESTRO), and the European Society of Pathology    (ESP) jointly developed clinically relevant and evidence-based guidelines covering  cervical cancer staging, management and follow-up for patients    with cervical.<br><br>ESGO would like to thank the international experts panel and external reviewers for devoting their time and efforts to develop these guidelines.<br><br> ",
     
      languages: [
        {"name":"English","url":"https://guidelines.esgo.org/media/2018/04/ESGO_Cervical-Cancer_A6.pdf"}
     ],
      order:4,
      
    }
  
  ];


  constructor(public fs: FiredbService) { }

  ngOnInit() {
    this.pgLength=0;
    this.pgCollectionRef = this.fs.getPocketGuides();
    this.pg$ = this.pgCollectionRef.snapshotChanges().map(actions => {
      return actions.map(action => {
        this.pgLength++;
        const data = action.payload.doc.data() as PocketGuide;
        const id = action.payload.doc.id;
        return { id, ...data };
      });
    });

   // this.initdb();
  }
  removeGuide(id:string, title: string){
    let del: boolean;

    del = confirm("Remove Guide:" + title);
    if(del) {
      //alert("BOOM! events/" + eventid);
      this.fs.removePocketGuide(id);
      //this.getMessages();
    }
  }
  initdb(){
   let ar = [];
   let page = this;
   ar = this.guides;
   ar.forEach( function(g:PocketGuide, idx: number){

    page.fs.pushPocketGuide(g);
   });
 }
}
