import { Component, OnInit } from '@angular/core';
import { EsgoAdvertisement } from '../firedb.service';
import { FiredbService } from './../firedb.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-advertisements',
  templateUrl: './add-advertisements.component.html',
  styleUrls: ['./add-advertisements.component.css']
})
export class AddAdvertisementsComponent implements OnInit {

  advertisement:EsgoAdvertisement = <EsgoAdvertisement> {};
  editorConfig = {
    "editable": true,
    "spellcheck": true,
    "height": "auto",
    "minHeight": "0",
    "width": "auto",
    "minWidth": "0",
    "translate": "yes",
    "enableToolbar": true,
    "showToolbar": true,
    "placeholder": "Enter  this here...",
    "imageEndPoint": "",
    "toolbar": [
        ["bold", "italic","color","underline"],
        ["undo", "redo"]
    ]
  }
  constructor(private firedb:FiredbService,private route:Router) { }

  ngOnInit() {
  }

  createAdvertisement(){
   
    this.firedb.pushAdvertisement(this.advertisement);
    this.route.navigate(['advertisements']);
  }

}
