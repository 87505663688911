import { Component, OnInit } from '@angular/core';
import {FiredbService} from '../firedb.service';


@Component({
  selector: 'app-comments-list',
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.css']
})
export class CommentsListComponent implements OnInit {
  commentRef$: any;
  comments$: any[];
  commentsOrg: any[];
  l: number;
  fltStatus: string;
  fltGuide: string;
  fltStart: string;
  fltEnd : string;

  flrParams: string;

  constructor(public fs: FiredbService) {
    this.flrParams = "";
   }

  ngOnInit() {
    this.l=0;
    this.fltEnd = '';
    this.fltStart = '';
    this.fltGuide = 'All';
    this.fltStatus = 'All';

    this.fs.getComments().valueChanges().subscribe(comments =>{
      this.comments$ = comments;
      this.commentsOrg =comments;
     
      this.l = this.comments$.length;
    })
      
    // this.appuser$ = this.appuserCollectionRef.snapshotChanges().map(actions => {
    //   return actions.map(action => {
    //     this.appuserLength++;
    //     const data = action.payload.doc.data() as AppUser;
    //     const id = action.payload.doc.id;
    //     return { id, ...data };
    //   });
    // });
  }
  filterResults(){
    //redo
    this.comments$ = this.commentsOrg ;
    this.flrParams = "filter=1";


    if (this.fltStatus !='All') {
      this.flrParams += "&status=" + this.fltStatus;

     this.comments$ =  this.comments$.filter( row =>{
        return row.status == this.fltStatus;
      })
    }
    if (this.fltGuide !='All') {
      this.flrParams += "&guide=" + this.fltGuide;
      this.comments$ =  this.comments$.filter( row =>{
         return row.guide == this.fltGuide;
       })
     }

     if (this.fltStart !='') {
      this.flrParams += "&start=" + this.fltStart;
      this.comments$ =  this.comments$.filter( row =>{
         return row.createdAt >= this.fltStart;
       })
     }

     if (this.fltEnd !='') {
      this.flrParams += "&end=" + this.fltEnd;
      this.comments$ =  this.comments$.filter( row =>{
         return row.createdAt <= this.fltEnd;
       })
     }
     this.l = this.comments$.length;

  }

}
