import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FiredbService, PocketGuide } from '../firedb.service';
import { Observable } from 'rxjs/Observable';
import { isArray } from 'util';

@Component({
  selector: 'app-pocketguide-edit',
  templateUrl: './pocketguide-edit.component.html',
  styleUrls: ['./pocketguide-edit.component.css']
})
export class PocketguideEditComponent implements OnInit {

  gd:  Observable<PocketGuide>;
  gdid: string;
  sub: any;
  gddata: PocketGuide = <PocketGuide>{};

  lang_url:string;
  lang_name:string;

  constructor(private fs: FiredbService, private route: ActivatedRoute, private rt: Router) {

   }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.gdid = params['id']; 
      this.getPocketGuide(this.gdid);
    });
    this.lang_url = "";
    this.lang_name = "";

  }
  getPocketGuide(gdid) {
    this.gd =  this.fs.getPocketGuide(gdid).snapshotChanges().map(fsdata => {
      
       const data = fsdata.payload.data() as PocketGuide;
       return data;
     });
     this.gd.subscribe(dt => this.gddata = dt);
   }
  pushGuide(){

    this.fs.updatePocketGuide(this.gddata, this.gdid);
    this.rt.navigate(['pocketguides']);

  }

  removeLang(t:string){
    let l = [];
    l = this.gddata.languages.filter( function(el) {
      return el.name != t;
    });
    this.gddata.languages = l;
    

  }
  addLang(){
    let l = {
      "name": this.lang_name,
      "url": this.lang_url
    };
   //if this is the first guide we need to define the array first
   if (!isArray(this.gddata.languages)){
     this.gddata.languages = [];
   }

    this.gddata.languages.push(l);
    this.lang_name = "";
    this.lang_url ="";
  }
}

