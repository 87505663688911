import { Component, OnInit } from '@angular/core';
import { AngularFirestoreCollection} from '@angular/fire/firestore';;
import { FiredbService, PushNote} from '../firedb.service';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-push',
  templateUrl: './push.component.html',
  styleUrls: ['./push.component.css']
})
export class PushComponent implements OnInit {
   pushmessage:string;
  pushnoteCollectionRef: AngularFirestoreCollection<PushNote>;
  pushnote$: Observable<PushNote[]>;

  constructor(private fs: FiredbService, private http: HttpClient, private toastr: ToastrService) { }

  ngOnInit() {
    this.getNotes();
  }

  sendNow(){
    if(this.pushmessage.length > 5) {
      var body = {"body": this.pushmessage};
      console.log("Sending:" + this.pushmessage);
      var url = "https://app.esgo.org:4000/pushmsg";
      var resp = this.http.post(url,body);
      
      resp.subscribe();
        //Log in FS
      let pnObj =<PushNote>{};
      pnObj.txtmsg = this.pushmessage;
      pnObj.senddate = new Date().toString();
      this.saveNote(pnObj);
      

      this.pushmessage = "";
      this.toastr.success("Notification","Push Message Sent Successfully");
      
    }
  }

  saveNote(pn: PushNote) {
    this.fs.pushPushNote(pn);
    this.getNotes();
  }

  getNotes(){
    this.pushnoteCollectionRef = this.fs.getPushNotes();
    this.pushnote$ = this.pushnoteCollectionRef.snapshotChanges().map(actions => {
     // this.esgomsgLength = 0;
      return actions.map(action => {
        //this.esgomsgLength++;
        const data = action.payload.doc.data() as PushNote;
        const id = action.payload.doc.id;
        return { id, ...data };
      });
    });
  }

}
