import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { MenuAdminComponent } from './menu-admin/menu-admin.component';
import { EventListComponent } from './event-list/event-list.component';
import { MessageListComponent } from './message-list/message-list.component';
import { ResourcesListComponent } from './resources-list/resources-list.component';
import { PushComponent } from './push/push.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MembersListComponent } from './members-list/members-list.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule} from '@angular/fire/firestore';
import { FiredbService } from './firedb.service';
import { EventEditComponent } from './event-edit/event-edit.component';
import { EventCreateComponent} from './event-create/event-create.component';
import { FormsModule } from '@angular/forms';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { MessageCreateComponent } from './message-create/message-create.component';
import { MessageEditComponent } from './message-edit/message-edit.component';
import { ResourceEditComponent } from './resource-edit/resource-edit.component';
import { ResourceCreateComponent } from './resource-create/resource-create.component';
import { LoginComponent } from './login/login.component';
import { CommentsComponent } from './comments/comments.component';
import { AuthGuard } from './auth.guard';
import { UserService } from './user.service';
import { MobileEventService } from './mobile-event.service'
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PocketguideListComponent } from './pocketguide-list/pocketguide-list.component';
import { PocketguideCreateComponent } from './pocketguide-create/pocketguide-create.component';
import { PocketguideEditComponent } from './pocketguide-edit/pocketguide-edit.component';
import { CommentsListComponent } from './comments-list/comments-list.component';
import { EventReportComponent } from './event-report/event-report.component';
import { AdvertisementsComponent } from './advertisements/advertisements.component';
import { AddAdvertisementsComponent } from './add-advertisements/add-advertisements.component';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AdvertisementsEditComponent } from './advertisements-edit/advertisements-edit.component';
import { ReportsComponent } from './reports/reports.component';
import { AllDataComponent } from './reports/all-data/all-data.component';
import { DataMessagesComponent } from './reports/data-messages/data-messages.component';
import { DataAdvertsComponent } from './reports/data-adverts/data-adverts.component';
import { DataPushComponent } from './reports/data-push/data-push.component';
//import { NgxEditorModule } from 'ngx-editor';

export const firebaseConfig = {
  apiKey: "AIzaSyD7F869KWPPOLay5uDmthNtcppAimLjl6s",
  authDomain: "esgo-guides-dev.firebaseapp.com",
  databaseURL: "https://esgo-guides-dev.firebaseio.com",
  projectId: "esgo-guides-dev",
  storageBucket: "esgo-guides-dev.appspot.com",
  messagingSenderId: "99435492750"
};

const appRoutes: Routes = [
  { path: '',canActivate:[AuthGuard] , component: EventListComponent },
  { path: 'events', canActivate:[AuthGuard] , component: EventListComponent },
  { path: 'events/create', component: EventCreateComponent },
  { path: 'events/:id', canActivate:[AuthGuard] ,component: EventEditComponent},
  { path: 'ereport/:type/:id', canActivate:[AuthGuard] ,component: EventReportComponent},

  { path: 'pocketguides', canActivate:[AuthGuard] , component: PocketguideListComponent },
  { path: 'pocketguides/create', component: PocketguideCreateComponent },
  { path: 'pocketguides/:id', component: PocketguideEditComponent},
  { path: 'messages',    canActivate:[AuthGuard] ,  component: MessageListComponent },
  { path: 'messages/create',canActivate:[AuthGuard] , component: MessageCreateComponent},
  { path: 'messages/:id',canActivate:[AuthGuard] , component: MessageEditComponent},
  { path: 'resources', canActivate:[AuthGuard] ,component: ResourcesListComponent },
  { path: 'resources/create', canActivate:[AuthGuard] ,component: ResourceCreateComponent},
  { path: 'resources/:id', canActivate:[AuthGuard] ,component: ResourceEditComponent},
  { path: 'push',canActivate:[AuthGuard] , component: PushComponent },
  { path: 'members',canActivate:[AuthGuard] , component: MembersListComponent },
  { path: 'comments',canActivate:[AuthGuard] , component: CommentsListComponent },
  { path: 'login', component: LoginComponent},
  { path: 'advertisements/create',canActivate:[AuthGuard] ,  component: AddAdvertisementsComponent },
  { path: 'advertisements/:id',canActivate:[AuthGuard] ,  component: AdvertisementsEditComponent },
  { path: 'advertisements', canActivate:[AuthGuard] , component: AdvertisementsComponent },
  { path: 'reports', canActivate:[AuthGuard], component: ReportsComponent},
  { path: 'reports/rawdata', canActivate:[AuthGuard], component: AllDataComponent},
  { path: 'reports/dataMessages', canActivate:[AuthGuard], component: DataMessagesComponent},
  { path: 'reports/dataAdverts', canActivate:[AuthGuard], component: DataAdvertsComponent},
  { path: 'reports/dataPush', canActivate:[AuthGuard], component: DataPushComponent},
  
  
  { path: '**', component: PageNotFoundComponent }

];

@NgModule({
  declarations: [
    AppComponent,
    MenuAdminComponent,
    EventListComponent,EventCreateComponent,
    MessageListComponent,
    ResourcesListComponent,
    PushComponent,
   // HttpClientModule,
    PageNotFoundComponent,
      MembersListComponent,
      EventEditComponent,
      FileUploadComponent,
      MessageCreateComponent,
      MessageEditComponent,
      ResourceEditComponent,
      ResourceCreateComponent,
      LoginComponent,
      CommentsComponent,
      ForgotPasswordComponent,
      PocketguideListComponent,
      PocketguideCreateComponent,
      PocketguideEditComponent,
      CommentsListComponent,
      EventReportComponent,
      AdvertisementsComponent,
      AddAdvertisementsComponent,
      AdvertisementsEditComponent,
      ReportsComponent,
      AllDataComponent,
      DataMessagesComponent,
      DataAdvertsComponent,
      DataPushComponent
  ],
  imports: [
 
  BrowserModule,
    FormsModule,
    AngularFireAuthModule,
    HttpClientModule,
   // NgxEditorModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ positionClass: 'inline' }),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    )
  ],
  providers: [ FiredbService, AuthGuard, UserService,ToastrService, MobileEventService],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
],
})
export class AppModule { }
