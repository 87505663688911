import { Component, OnInit } from '@angular/core';
import { FiredbService,Resource } from '../firedb.service';
import {  AngularFirestoreCollection} from '@angular/fire/firestore';;
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-resources-list',
  templateUrl: './resources-list.component.html',
  styleUrls: ['./resources-list.component.css']
})
export class ResourcesListComponent implements OnInit {
  esgoresCollectionRef: AngularFirestoreCollection<Resource>;
  esgores$: Observable<Resource[]>;
  esgoresLength: number;

  constructor(private fs:FiredbService) { }

  ngOnInit() {
    this.getResources();
  }

  getResources(){
    this.esgoresCollectionRef = this.fs.getResources("all");
    this.esgores$ = this.esgoresCollectionRef.snapshotChanges().map(actions => {
      this.esgoresLength = 0;
      return actions.map(action => {
        this.esgoresLength++;
        const data = action.payload.doc.data() as Resource;
        const id = action.payload.doc.id;
        return { id, ...data };
      });
    });

  }
  removeResource(resid,title) {
    let del: boolean;

    del = confirm("Remove Resource:" + title);
    if(del) {
      
      this.fs.removeResource(resid);
      this.getResources();
    }
  }

}
