import { Component, OnInit } from '@angular/core';
import { EsgoAdvertisement } from '../firedb.service';
import { FiredbService } from './../firedb.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-advertisements-edit',
  templateUrl: './advertisements-edit.component.html',
  styleUrls: ['./advertisements-edit.component.css']
})
export class AdvertisementsEditComponent implements OnInit {
  advertisement:EsgoAdvertisement = <EsgoAdvertisement> {};
  id: string;
 
  constructor(public fs: FiredbService, public router:Router, public activatedRoute: ActivatedRoute) { }

  ngOnInit() {
  this.activatedRoute.params.subscribe(items =>{
   this.id = items.id
   this.getAdvert()
 })
  }
  getAdvert(){
    this.fs.getAdvertisement(this.id).subscribe(ad =>{
      this.advertisement = ad
    })
  }
  save(){
    //COnvert Dates Back to Timestamps
   
    this.fs.updateAdvertisement(this.id, this.advertisement)
    this.router.navigate(['/advertisements'])
  }

}
